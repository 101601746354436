import React from "react"

import "./LocationInfo.scss"

import { Text, Image, GeneralHours } from "./Core"
import { ButtonGroupMap } from "./Button"
import { Section } from "./Containers"

const LocationInfo = ({
  data,
  location,
  relatedLocations,
  napRef,
  hideAddress
}) => {
  const combinedLocations = [location].concat(relatedLocations)
  const hasRelation = combinedLocations.length > 1
  return (
    <Section className="location-info row" id="nap">
      <div className="columns">
        <div className="column is-2" />
        <div className="column px-40--mobile" id="nap" ref={napRef}>
          <Text text={data.text} />
          <div className="nap-list">
            {data.listOne && <Text text={data.listOne} />}
            {data.listTwo && <Text text={data.listTwo} />}
          </div>
        </div>
        <div className="column is-2" />
      </div>

      {hideAddress && (
        <div className="columns">
          <div className="column is-2" />
          <div className="column">
            <div
              className={`map-address-grid ${
                combinedLocations.length === 2 ? "has-2" : ""
              }`}>
              <div
                className={`info-address ${
                  combinedLocations.length === 2 ? "has-2" : ""
                }`}>
                {combinedLocations.map((info, idx) => (
                  <div className="location-block" key={info.locationName}>
                    {idx === 0 ? (
                      <h4 className="mb-1">
                        {data.heading
                          ? data.heading
                          : `Find Us in ${info.cityName}`}
                      </h4>
                    ) : (
                      <span
                        className="is-hidden-mobile"
                        style={{
                          display: "block",
                          height: "2.4rem"
                        }}
                      />
                    )}
                    {!data.showAddress ? (
                      ""
                    ) : (
                      <div>
                        <a
                          href={info.googlePlaceLink}
                          target="_blank"
                          title={`Get directions to our ${info.locationName} location.`}
                          rel="noreferrer">
                          {`${info.streetNumber} ${info.streetName}`}
                          <div className="address-spacer" />
                          {`${info.cityName}, ${info.stateAbbr} ${info.cityZip}`}
                        </a>
                      </div>
                    )}

                    {!data.showPhone ? (
                      ""
                    ) : (
                      <div className="mt-1">
                        <h6>Phone</h6>
                        <a href={`tel:+1${info.phone}`} title="Give us a call">
                          {info.phone}
                        </a>
                      </div>
                    )}

                    <div className="mt-1">
                      <h6>Fax</h6>
                      <p className="mt-0">{info.fax}</p>
                    </div>

                    {!data.showEmail ? (
                      ""
                    ) : (
                      <div className="mt-1">
                        <h6>Email</h6>
                        <a href={`mailto:${info.email}`} title="Email us">
                          {info.email}
                        </a>
                      </div>
                    )}

                    <GeneralHours
                      heading="Hours"
                      className="mt-1 hours"
                      language="en"
                      contact
                      locationHours={location.locationHours}
                    />
                  </div>
                ))}
                {/* <div className="other-location-buttons">
                  <h4>Other Locations</h4>
                  <div>
                    <ButtonGroupMap buttons={data.buttons} />
                  </div>
                </div> */}
              </div>

              {location.googleMapsIframe && (
                <Text
                  className="iframe--16-9"
                  text={location.googleMapsIframe}
                />
              )}
            </div>
          </div>
          <div className="column is-2" />
        </div>
      )}
    </Section>
  )
}

export default LocationInfo
