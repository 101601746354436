import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import SEO from "../components/seo"

import { Image, Text } from "../components/Core"
import { Heading } from "../components/Hero"
import TopSection from "./about/TopSection"
import OurMission from "./about/OurMission"
import MeetOurDoctors from "./about/MeetOurDoctors"
import OurReviews from "./about/OurReviews"
import MeetOurTeam from "./about/MeetOurTeam"
import ReasonsWhy from "./about/ReasonsWhy"
import BannerSection from "./about/BannerSection"
import { Columns, Section } from "../components/Containers"
import { CallToAction } from "../components/CTA"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button } from "../components/Button"

const AboutUsPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  const doctors = post.meetOurDoctors.images
  const team = data.team.nodes[0].meetOurTeam.staffImages
  return (
    <Layout language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <div className=" about-us">
        <div className="about-us__hero">
          <Columns sideColumnsSize={2}>
            <div className="column">
              <Text
                className="px-40--mobile mb-0"
                as="h1"
                text={post.heading}
              />
              <Image
                wrapperClassName="is-hidden-touch"
                publicId={post.mainVideo.imageId}
              />
              <div className="is-hidden-desktop">
                <Image publicId={post.mainVideo.imageIdMobile} />
              </div>
            </div>
          </Columns>

          <div className="angled-container color-back">
            <Columns sideColumnsSize={4}>
              <div className="column">
                {post.topBlurb && (
                  <Text
                    useStringReplace
                    className="mb-0 mt-2--mobile px-40--mobile"
                    text={post.topBlurb}
                  />
                )}

                {post.mainVideo.youtube && (
                  <TheaterVideo
                    buttonClass="contained mx-auto--mobile"
                    videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
                    controls
                    playing
                    language={language}
                  />
                )}
              </div>
              <div className="column is-2"></div>
            </Columns>

            {post.ourMission && (
              <OurMission post={post} language={language} sideColumnIs={4} />
            )}

            {post.meetOurDoctors.images.length === 1 ? (
              <Section zeroBottom className="">
                <Columns sideColumnsSize={0}>
                  <div className="column">
                    <div className="single-doctor grid">
                      <div className="single-doctor__content">
                        <h4>Meet Our Doctor</h4>
                        <Text
                          className="h1-style"
                          as="h3"
                          text={doctors[0].doctor.caption}
                        />
                        <Text
                          className="large"
                          as="p"
                          text={doctors[0].doctor.blurb}
                        />
                        <div className="flex single-doctor__cta">
                          <Button
                            href={doctors[0].doctor.url}
                            buttonText="Learn More"
                            minimal
                          />
                          {doctors[0].doctor.youtube && (
                            <TheaterVideo
                              buttonClass="minimal"
                              videoUrl={`https://www.youtube.com/watch?v=${doctors[0].doctor.youtube}`}
                              controls
                              playing
                              language={language}
                            />
                          )}
                        </div>
                      </div>
                      <div className="single-doctor__image">
                        <Image publicId={doctors[0].doctor.imageId} />
                      </div>
                    </div>
                  </div>
                </Columns>
              </Section>
            ) : (
              <MeetOurDoctors post={post} language={language} colorBack />
            )}
          </div>
          <OurReviews post={post} language={language} />
          <ReasonsWhy
            // roundedTop
            // mobileTranslateUp
            // colorBack
            heading={post.reasonsWhySection.heading}
            hasThisSection={post.reasonsWhySection.hasThisSection}
            list={post.reasonsWhySection.reasonsWhyColumn}
            language={language}
            hasBorderedCards
          />

          <MeetOurTeam
            // colorBack={language === "en"}
            post={post}
            team={team}
            language={language}
          />

          {post.bannerSection && post.bannerSection.hasThisSection && (
            <BannerSection
              text={post.bannerSection.text}
              image={post.bannerSection.logoId}
              // colorBack
            />
          )}
          <CallToAction
            className="angled-top"
            colorBack
            heading={post.callToAction.heading}
            blurb={post.callToAction.blurb}
            buttons={post.callToAction.buttons}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            blurb
            heading
            number
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          useImageStrip
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
          imageStrip {
            youtube
            images {
              imageId
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              url
              blurb
              youtube
            }
          }
          text
        }
        metaTitle
        metaDescription
        title
        mainVideo {
          imageId
          imageIdMobile
          youtube
        }
        bannerSection {
          hasThisSection
          logoId
          text
        }
        callToAction {
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
      }
    }
    team: allUniquePagesJson(
      filter: { template: { eq: "about-us" }, language: { eq: "en" } }
    ) {
      nodes {
        meetOurTeam {
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
              spanishTitle
            }
          }
        }
      }
    }
  }
`

export default AboutUsPage
